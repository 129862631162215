import { useCallback, useRef } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import employerAutosearchButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/autosearch/employer_autosearch_button_click';
import employerAutosearchElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/autosearch/employer_autosearch_element_shown';
import { Button, ButtonSize, TooltipHover, Text } from '@hh.ru/magritte-ui';
import { BellOutlinedSize24, BellCrossedOutlinedSize24, CheckOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SubscribeButton from 'src/components/Employer/Subscribe';
import { useSubscribeContext } from 'src/components/Employer/Subscribe/SubscribeContext';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    employerViewVacanciesSubscribe: 'employer.view.sidebar.button.vacanciesSubscribe',
    menuItemSubscribe: 'employerView.header.subscribe.menuItem.subscribe',
    subscribedAlready: 'employerView.header.subscribe.already',
    subscribedAlreadyTooltip: 'employerView.header.subscribe.already.tooltip',
};

interface SubscribeProps {
    stretched?: boolean;
    isMenuItem?: boolean;
    size?: ButtonSize;
    onClick?: VoidFunction;
}

const Subscribe: TranslatedComponent<SubscribeProps> = ({
    stretched,
    trls,
    size = 'small',
    isMenuItem = false,
    onClick,
}) => {
    const { count, isSubscribeAllowed } = useSubscribeContext();
    const activatorRef = useRef(null);
    const employerInfo = useSelector(({ employerInfo }) => employerInfo);
    const { id: employerId } = employerInfo;

    const getButtonText = useCallback(() => {
        if (count) {
            return trls[TrlKeys.subscribedAlready];
        }

        if (isMenuItem) {
            return trls[TrlKeys.menuItemSubscribe];
        }
        return trls[TrlKeys.employerViewVacanciesSubscribe];
    }, [count, isMenuItem, trls]);

    const getIcon = useCallback(() => {
        if (count) {
            return isMenuItem ? <BellCrossedOutlinedSize24 /> : <CheckOutlinedSize16 />;
        }

        if (isMenuItem) {
            return <BellOutlinedSize24 />;
        }

        return undefined;
    }, [count, isMenuItem]);

    if (!isSubscribeAllowed) {
        return null;
    }

    return (
        <>
            {!!count && (
                <TooltipHover placement="bottom-center" size="small" activatorRef={activatorRef}>
                    <Text>{trls[TrlKeys.subscribedAlreadyTooltip]}</Text>
                </TooltipHover>
            )}
            <ElementShownAnchor
                fn={employerAutosearchElementShown}
                elementName={count ? 'employer_autosearch_link' : 'employer_autosearch_subscribe'}
                employerId={String(employerId)}
            >
                <Button
                    ref={activatorRef}
                    Element={SubscribeButton}
                    size={size}
                    onClick={() => {
                        onClick?.();
                        employerAutosearchButtonClick({
                            buttonName: count ? 'employer_autosearch_link' : 'employer_autosearch_subscribe',
                            employerId: String(employerId),
                        });
                    }}
                    style="neutral"
                    mode={isMenuItem ? 'tertiary' : 'secondary'}
                    stretched={stretched}
                    icon={getIcon()}
                    data-qa={count ? 'vacancy-serp__search-saved' : 'employer__search-saved'}
                >
                    {getButtonText()}
                </Button>
            </ElementShownAnchor>
        </>
    );
};

export default translation(Subscribe);
