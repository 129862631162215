import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import employerAchievementCardButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_achievement_card_button_click';
import {
    AchievementType,
    employerAchievementCardElementShownRaw,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_achievement_card_element_shown';
import employerAchievementCardTooltipButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_achievement_card_tooltip_button_click';
import { employerAchievementCardTooltipElementShownRaw } from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_achievement_card_tooltip_element_shown';
import {
    BottomSheet,
    BottomSheetFooter,
    Button,
    NavigationBar,
    TooltipHover,
    Text,
    useBreakpoint,
    VSpacing,
} from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import Item, { ItemProps } from 'src/components/Employer/EmployerView/About/Item';

interface AdvantageItemProps extends ItemProps {
    tooltipButtonText: string;
    tooltipDescription: ReactNode;
    url?: string;
    type: AchievementType;
    onTooltipButtonClick?: VoidFunction;
}

const AdvantageItem: TranslatedComponent<AdvantageItemProps> = ({
    tooltipButtonText,
    tooltipDescription,
    style,
    url,
    type,
    trls,
    onTooltipButtonClick,
    ...props
}) => {
    const activatorRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const employerId = useSelector((state) => state.employerInfo.id);

    const { isMobile } = useBreakpoint();

    useEffect(() => {
        employerAchievementCardElementShownRaw({ employerId: String(employerId), achievementType: type });
    }, [employerId, type]);

    const handleButtonClick = useCallback(() => {
        employerAchievementCardTooltipButtonClick({ employerId: String(employerId), achievementType: type });
        onTooltipButtonClick?.();
        setVisible(false);
    }, [employerId, onTooltipButtonClick, type]);

    const buttonProps = {
        mode: isMobile ? 'primary' : 'secondary',
        size: isMobile ? 'medium' : 'small',
        style: isMobile ? 'accent' : 'contrast',
        onClick: handleButtonClick,
        stretched: true,
    } as const;

    const TooltipButton = url ? (
        <Button Element={SPALink} to={url} target="_blank" key="tooltip-button" {...buttonProps}>
            {tooltipButtonText}
        </Button>
    ) : (
        <Button key="tooltip-button" {...buttonProps}>
            {tooltipButtonText}
        </Button>
    );

    const handleAppear = useCallback(() => {
        employerAchievementCardTooltipElementShownRaw({ employerId: String(employerId), achievementType: type });
    }, [employerId, type]);

    return (
        <>
            <BottomSheet
                visible={visible}
                onClose={() => setVisible(false)}
                showDivider="always"
                footer={<BottomSheetFooter>{TooltipButton}</BottomSheetFooter>}
                header={<NavigationBar title={props.title} showDivider="always" />}
                onAppear={handleAppear}
            >
                <Text typography="label-1-regular">{tooltipDescription}</Text>
                <VSpacing default={20} />
            </BottomSheet>
            <TooltipHover
                activatorRef={activatorRef}
                placement="top-center"
                buttons={TooltipButton}
                onAppear={handleAppear}
            >
                {tooltipDescription}
            </TooltipHover>
            <Item
                ref={activatorRef}
                style={style}
                onClick={() => {
                    employerAchievementCardButtonClick({ employerId: String(employerId), achievementType: type });
                    setVisible(true);
                }}
                {...props}
            />
        </>
    );
};

export default translation(AdvantageItem);
