import { FC } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';

import Advantages from 'src/components/Employer/EmployerView/About/Advantages';
import CompanyInfo from 'src/components/Employer/EmployerView/About/CompanyInfo';
import CompanySite from 'src/components/Employer/EmployerView/About/CompanySite';

const About: FC = () => {
    return (
        <VSpacingContainer default={24}>
            <CompanyInfo />
            <Advantages />
            <CompanySite />
        </VSpacingContainer>
    );
};

export default About;
