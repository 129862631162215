import { FC } from 'react';

import Advantages from 'src/components/Employer/EmployerView/About/Advantages';
import CompanyInfo from 'src/components/Employer/EmployerView/About/CompanyInfo';
import CompanySite from 'src/components/Employer/EmployerView/About/CompanySite';

import styles from './styles.less';

const EmployerChameleonAbout: FC = () => {
    return (
        <div className={styles.about}>
            <CompanyInfo />
            <Advantages />
            <CompanySite />
        </div>
    );
};

export default EmployerChameleonAbout;
