import { FC, PropsWithChildren } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';

import { useSelector } from 'src/hooks/useSelector';

import styles from './about.less';

const Wrapper: FC<PropsWithChildren> = ({ children }) => <VSpacingContainer default={24}>{children}</VSpacingContainer>;
const ChameleonWrapper: FC<PropsWithChildren> = ({ children }) => <div className={styles.items}>{children}</div>;

const ItemsWrapper: FC<PropsWithChildren> = ({ children }) => {
    const hasChameleon = useSelector(({ hasChameleon }) => hasChameleon);
    const ComponentWrapper = hasChameleon ? ChameleonWrapper : Wrapper;

    return <ComponentWrapper>{children}</ComponentWrapper>;
};

export default ItemsWrapper;
