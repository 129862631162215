import { FC, PropsWithChildren } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';

import { useSelector } from 'src/hooks/useSelector';

const EmployerChameleonContentWrapper: FC<PropsWithChildren> = ({ children }) => {
    const isMagritteEmployerPageHeaderExp = useSelector((state) => state.isMagritteEmployerPageHeaderExp);
    const chameleonWidePage = useSelector((state) => state.chameleonWidePage);

    if (!isMagritteEmployerPageHeaderExp) {
        if (chameleonWidePage) {
            return <>{children}</>;
        }

        return (
            <ColumnsWrapper>
                <Column l="16" m="12" s="8" xs="4">
                    {children}
                </Column>
            </ColumnsWrapper>
        );
    }

    return (
        <ColumnsWrapper>
            <Column l="16" m="12" s="8" xs="4">
                <VSpacing default={40} />
                {children}
            </Column>
        </ColumnsWrapper>
    );
};

export default EmployerChameleonContentWrapper;
