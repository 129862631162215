import { ElementShownAnchor } from '@hh.ru/analytics-js';
import sidebarButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/branding/employer_page/sidebar_button_click';
import sidebarElementShown from '@hh.ru/analytics-js-events/build/xhh/common/branding/employer_page/sidebar_element_shown';
import { translation } from '@hh.ru/front-static-app';
import { Card } from '@hh.ru/magritte-ui';
import { LinkOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSelector } from 'src/hooks/useSelector';

import Item from 'src/components/Employer/EmployerView/About/Item';
import ItemsWrapper from 'src/components/Employer/EmployerView/About/ItemsWrapper';

const TrlKeys = {
    site: 'employerView.rightPanel.companyInfo.site',
};

const CompanySite: TranslatedComponent = ({ trls }) => {
    const site = useSelector((state) => state.employerInfo.site);
    const accepted = useSelector((state) => state.employerInfo.accepted);

    if (!(site && accepted)) {
        return null;
    }

    return (
        <ElementShownAnchor fn={sidebarElementShown} elementName="site_link">
            <Card padding={24} borderWidth="default" borderRadius={24} stretched>
                <ItemsWrapper>
                    <Item
                        style="accent"
                        icon={<LinkOutlinedSize24 initialColor="accent" />}
                        title={site.href}
                        subtitle={trls[TrlKeys.site]}
                        titleAsLink
                        onLinkClick={() => sidebarButtonClick({ buttonName: 'site_link' })}
                    />
                </ItemsWrapper>
            </Card>
        </ElementShownAnchor>
    );
};

export default translation(CompanySite);
