import { FC } from 'react';

import { Card, VSpacing, GridColumn, GridRow, GridLayout } from '@hh.ru/magritte-ui';

import EmployerPageTabContent from 'src/components/Employer/PageTabs/EmployerPageTabContent';
import { EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';
import EmployerPageTitle, { TileMode } from 'src/components/Employer/PageTitle';
import VacanciesGroupsByRegion from 'src/components/Employer/VacanciesGroupsByRegion';
import SearchFooterButton from 'src/components/Employer/VacancySearch/SearchFooterButton';
import Widgets from 'src/components/EmployerConstructor/WidgetsList';
import ConnectReviewsModal from 'src/components/EmployerReviews/ConnectReviewsModal';
import EmployerReviewsWidgetBig from 'src/components/EmployerReviews/WidgetBig';
import useViewDurationTracking from 'src/hooks/companies/useViewDurationTracking';
import { useIsRussia } from 'src/hooks/useCountries';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import About from 'src/components/Employer/EmployerView/About';
import Header from 'src/components/Employer/EmployerView/Header';
import EmptyDescription from 'src/components/Employer/EmployerView/Header/EmptyDescription';

const EmployerView: FC = () => {
    const employerName = useSelector((state) => state.employerInfo.name);
    const isRussia = useIsRussia();
    const isZP = useIsZarplataPlatform();
    const employerAccepted = useSelector(({ employerInfo }) => employerInfo.accepted);
    const descriptionText = useSelector((state) => state.employerInfo.description);
    const showReviewsWidget = !isZP && employerAccepted;
    const hasDescription = !!descriptionText;
    useViewDurationTracking();

    return (
        <EmployerPageTitle mode={TileMode.View} employerName={employerName}>
            <Header />
            <EmployerPageTabContent tab={EmployerPageTab.Description}>
                <GridLayout>
                    <GridRow>
                        <GridColumn xs={4} s={8} m={8} l={8}>
                            {hasDescription ? (
                                <Widgets />
                            ) : (
                                <>
                                    <VSpacing default={40} />
                                    <Card padding={24} borderWidth="default" borderRadius={24} stretched>
                                        <EmptyDescription />
                                    </Card>
                                </>
                            )}
                            {showReviewsWidget && <EmployerReviewsWidgetBig hasWrapper={false} />}
                        </GridColumn>
                        <GridColumn xs={4} s={8} m={4} l={4}>
                            <VSpacing default={40} />
                            <About />
                        </GridColumn>
                    </GridRow>
                </GridLayout>
            </EmployerPageTabContent>
            <EmployerPageTabContent tab={EmployerPageTab.Vacancies}>
                <GridLayout>
                    <GridColumn xs={4} s={8} m={12} l={12}>
                        <VSpacing default={24} />
                        <VacanciesGroupsByRegion />
                    </GridColumn>
                </GridLayout>
            </EmployerPageTabContent>
            <SearchFooterButton />
            <VSpacing default={24} />
            {isRussia && !isZP && <ConnectReviewsModal />}
        </EmployerPageTitle>
    );
};

export default EmployerView;
