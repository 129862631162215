import { useCallback, useState } from 'react';
import classNames from 'classnames';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import employerCompanyNameButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_company_name_button_click';
import { employerCompanyNameElementShownRaw } from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_company_name_element_shown';
import { employerHeaderTooltipElementShownRaw } from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_header_tooltip_element_shown';
import verificationButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/branding/employer_page/verification_button_click';
import verificationElementShown from '@hh.ru/analytics-js-events/build/xhh/common/branding/employer_page/verification_element_shown';
import { Title, VSpacing } from '@hh.ru/magritte-ui';
import { ChevronDownOutlinedSize24, ChevronUpOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import TrustedEmployerCheck, { TrustedEmployerCheckSize } from 'src/components/Employer/TrustedEmployerCheck';
import EmployerReviewsWidgetSmall from 'src/components/EmployerReviews/WidgetSmall';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import MiniAdvantages from 'src/components/Employer/EmployerView/Header/MiniAdvantages';

import styles from './description.less';

const Description: TranslatedComponent = () => {
    const companyName = useSelector((state) => state.employerInfo.name);
    const employerId = useSelector((state) => state.employerInfo.id);
    const isTrusted = useSelector((state) => state.employerInfo.isTrusted);
    const [clamped, setClamped] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const titleRef = useCallback(
        (node: HTMLSpanElement | null) => {
            if (!node) {
                return;
            }
            const titleNode = node.parentElement;
            if (!titleNode) {
                return;
            }

            const isClamped = titleNode.scrollHeight > titleNode.clientHeight;
            setClamped(isClamped);
            employerCompanyNameElementShownRaw({
                employerId: String(employerId),
                employerName: companyName || '',
                action: isClamped ? 'show_company_name' : 'no_action',
            });
        },
        [companyName, employerId]
    );

    return (
        <div>
            <div
                className={classNames(styles.title, {
                    [styles.titleNotClamped]: !clamped,
                })}
            >
                <Title Element="h1" maxLines={expanded ? undefined : 1}>
                    <span ref={titleRef}>{companyName}</span>
                </Title>
                <div className={styles.icons}>
                    {clamped && (
                        <span
                            className={styles.collapseIcon}
                            onClick={() => {
                                employerCompanyNameButtonClick({
                                    employerId: String(employerId),
                                    employerName: companyName || '',
                                    action: expanded ? 'hide_company_name' : 'show_company_name',
                                });
                                setExpanded(!expanded);
                            }}
                        >
                            {expanded ? <ChevronUpOutlinedSize24 /> : <ChevronDownOutlinedSize24 />}
                        </span>
                    )}
                    {isTrusted && (
                        <ElementShownAnchor fn={verificationElementShown}>
                            <TrustedEmployerCheck
                                onTooltipAppear={() =>
                                    employerHeaderTooltipElementShownRaw({
                                        employerId: String(employerId),
                                        tooltipType: 'verification',
                                    })
                                }
                                isMagritte
                                size={TrustedEmployerCheckSize.Large}
                                onClick={() => verificationButtonClick()}
                                tooltipPlacement="bottom-center"
                                tooltipSize="small"
                            />
                        </ElementShownAnchor>
                    )}
                </div>
            </div>
            <VSpacing default={6} />
            <div className={styles.root}>
                <EmployerReviewsWidgetSmall />
                <MiniAdvantages />
            </div>
        </div>
    );
};

export default translation(Description);
