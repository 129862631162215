import { useMemo } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import employerCompanyInfoContainerElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_company_info_container_element_shown';
import { translation } from '@hh.ru/front-static-app';
import { Card, Text } from '@hh.ru/magritte-ui';
import { BriefcaseOutlinedSize24, DocumentOutlinedSize24, MapPinOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSelector } from 'src/hooks/useSelector';

import ClampedItem from 'src/components/Employer/EmployerView/About/ClampedItem';
import Item from 'src/components/Employer/EmployerView/About/Item';
import ItemsWrapper from 'src/components/Employer/EmployerView/About/ItemsWrapper';

const TrlKeys = {
    city: 'employerView.rightPanel.companyInfo.city',
    industries: 'employerView.rightPanel.companyInfo.industries',
    category: 'employerView.rightPanel.companyInfo.category',
};

const INDUSTRIES_SEPARATOR = ';';

const CompanyInfo: TranslatedComponent = ({ trls }) => {
    const address = useSelector((state) => state.employerInfo.address);
    const employerCategories = useSelector((state) => state.employerCategories);
    const category = useSelector((state) => state.employerInfo.category);
    const industries = useSelector((state) => state.employerInfo.industries);
    const employerId = useSelector((state) => state.employerInfo.id);

    const currentCategory = employerCategories.find((item) => item.code.toLowerCase() === category);
    const hasIndustries = !!industries?.length;

    const infoList = useMemo(() => {
        const result = [];

        if (address) {
            result.push('area');
        }

        if (hasIndustries) {
            result.push('segment');
        }

        if (currentCategory) {
            result.push('registration_type');
        }

        return result.join(',');
    }, [address, currentCategory, hasIndustries]);

    if (!(address || hasIndustries || currentCategory)) {
        return null;
    }

    return (
        <ElementShownAnchor
            fn={employerCompanyInfoContainerElementShown}
            employerId={String(employerId)}
            infoList={infoList}
        >
            <Card padding={24} borderWidth="default" borderRadius={24} stretched>
                <ItemsWrapper>
                    {address && <Item icon={<MapPinOutlinedSize24 />} title={address} subtitle={trls[TrlKeys.city]} />}
                    {hasIndustries && (
                        <ClampedItem
                            icon={<BriefcaseOutlinedSize24 />}
                            title={industries.map((industry) => industry.trl).join(', ')}
                            bottomSheetContent={industries.map((industry) => industry.trl).join('; ')}
                            tooltipTitle={industries.map((industry, index) => (
                                <Text key={industry.id}>
                                    {industry.trl}
                                    {industries.length - 1 !== index && INDUSTRIES_SEPARATOR}
                                </Text>
                            ))}
                            subtitle={trls[TrlKeys.industries]}
                        />
                    )}
                    {currentCategory && (
                        <Item
                            icon={<DocumentOutlinedSize24 />}
                            title={currentCategory.translation}
                            subtitle={trls[TrlKeys.category]}
                        />
                    )}
                </ItemsWrapper>
            </Card>
        </ElementShownAnchor>
    );
};

export default translation(CompanyInfo);
