import employerWantWorkHereButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_want_work_here_button_click';
import { translation } from '@hh.ru/front-static-app';
import { Button } from '@hh.ru/magritte-ui';
import { CheckOutlinedSize16, CheckOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    already: 'employerView.header.wantWorkHere.already',
};

const WantWorkHereAlready: TranslatedComponent<{ isMenuItem?: boolean }> = ({ trls, isMenuItem }) => {
    const employerId = useSelector((state) => state.employerInfo.id);

    return (
        <Button
            Element={SPALink}
            to="/applicant-services/services"
            size={isMenuItem ? 'medium' : 'small'}
            style="neutral"
            onClick={() =>
                employerWantWorkHereButtonClick({ employerId: String(employerId), action: 'dont_want_work_here' })
            }
            stretched
            mode={isMenuItem ? 'tertiary' : 'secondary'}
            icon={isMenuItem ? <CheckOutlinedSize24 /> : <CheckOutlinedSize16 />}
        >
            {trls[TrlKeys.already]}
        </Button>
    );
};

export default translation(WantWorkHereAlready);
